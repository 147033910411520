import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import base from '../styles/base.module.css';
import main from '../styles/main.module.css';
import theme from '../styles/theme.module.css';

import Layout from '../components/layout';

/**
 * Privacy and Security page component. Parent component.
 * @component
 *
 * @param   {string} location - URL data object
 * @returns {component}       - <PrivacySecurity location={string} />
 */

const PrivacySecurity = ({ location }) => (
  <Layout pageTitle="Privacy and Security" location={location}>
    <article
      className={cx(
        base.flex,
        base.flexColumn,
        base.itemsCenter,
        base.justifyStart,
        base.w100,
        main.mt60,
        main.mb60,
        main.postLegal,
        main.postSingle,
        main.px60
      )}
    >
      <h1 className={cx(base.mb5, base.tc, theme.colorDark)}>
        Privacy &amp; Security
      </h1>
      <section>
        <p>
          Decision Point takes the integrity, privacy and security of your data
          very seriously and is committed to safeguarding member health
          information by employing a robust Information Security program. To get
          in touch with our Chief Information Security Officer or for general
          inquiries about our Information Security program, please reach out to{' '}
          <a href="mailto:infosec@decisionpointhealth.com">
            infosec@decisionpointhealth.com
          </a>
        </p>
      </section>
    </article>
  </Layout>
);

PrivacySecurity.propTypes = {
  location: PropTypes.shape({}).isRequired,
};

export default PrivacySecurity;
